import React from "react";
import Helmet from 'react-helmet';


interface HeadProperties {
    title: string,
    description?: Nullable<string>,
    image?: Nullable<string>,
}

function Head(props:HeadProperties) {
    const siteUrl = "https://lemley.dev"

    const seo = {
        title: props.title,
        description: props.description ?? "",
        image: `${siteUrl}${props.image}`,
        url: `${siteUrl}`,
    };
    return (
        <Helmet title={seo.title} defaultTitle={seo.title}>
            <html lang="en"/>

            <meta name="description" content={seo.description}/>
            <meta name="image" content={seo.image}/>

            <meta property="og:title" content={seo.title}/>
            <meta property="og:description" content={seo.description}/>

            <meta property="og:url" content={seo.url}/>
            <meta property="og:type" content="website"/>
        </Helmet>
    )
}

export default Head;
