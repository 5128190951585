import React from 'react';

function LinkedIn() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" role="img" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
             className="feather feather-linkedin">
            <path
                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"/>
            <rect
                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                x="2" y="9" width="4" height="12"/>
            <circle
                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                cx="4" cy="4" r="2"/>
        </svg>
    )
}

export default LinkedIn;