import React from "react";
import me from "../images/me.png";
import "../scss/HeadShot.scss"


function HeadShot() {
    return (
        <div className="headShot">
            <div className="imageWrapper">
                <div><img src={me} alt="Headshot"/></div>
            </div>
        </div>
    )
}

export default HeadShot;