module.exports = {
    email: "mlemleyap@gmail.com",

    socialMedia: [
        {
            name: "GitLab",
            url: "https://gitlab.com/lemley/",
        },
        {
            name: "GitHub",
            url: "https://github.com/mlemley",
        },
        {
            name: "LinkedIn",
            url: "https://www.linkedin.com/in/michael-lemley/",
        },
    ],

    navLinks: [],
/*
    navLinks: [
        {
            name: 'About',
            url: '/#about',
        },
        {
            name: 'Experience',
            url: '/#jobs',
        },
        {
            name: 'Work',
            url: '/#projects',
        },
        {
            name: 'Contact',
            url: '/#contact',
        },
    ],
*/

    tech: [
        "Kotlin", "Android", "Ktor",
        "Gradle", "Junit", "Truth",
        "React", "Typescript", "Python",
    ]
}