import React from "react";
import "../scss/Footer.scss"
import {socialMedia} from '../config';
import Icon from "./Icon"

function Footer() {
    return (
        <footer>
            {
                socialMedia.length > 0 && (
                    <ul className="socialLinks">
                        {
                            socialMedia.map(({name, url}, i) => (
                                    <li key={i}>
                                        <a href={url} className="imageLink" target="_blank" rel="noreferrer">
                                            <Icon iconName={name}/>
                                        </a>
                                    </li>
                                )
                            )
                        }
                    </ul>
                )
            }
        </footer>
    )
}

export default Footer;