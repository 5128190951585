import React from 'react';
import {Head, Header, Hero, Footer} from "./components";

function App() {
    return (
        <>
            <Head title="Michael Lemley"/>
            <Header/>
            <main>
                <Hero/>
            </main>
            <Footer/>
        </>
    )
}

export default App;
