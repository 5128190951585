import React from "react";
import logo from "../images/logo.svg";
import {Resume, Menu} from "./index";
import "../scss/Header.scss"
import {navLinks} from '../config';

function Header() {
    return (
        <header>
            <nav>
                <img src={logo} className="logo" alt="logo"/>
                {navLinks.length > 0  && (
                    <>
                        <div className="navLinks">
                            <ol>
                                {
                                    navLinks.map(({url, name}, i) => (
                                        <li key={i}>
                                            <a href={url} className="borderless">{name}</a>
                                        </li>
                                    ))
                                }
                            </ol>
                            <Resume/>
                        </div>
                        <Menu/>
                    </>
                )
                }
            </nav>
        </header>
    )
}

export default Header;