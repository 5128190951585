import React, {useState, useRef} from "react";
import {navLinks} from '../config';
import {Resume} from "./index";
import {Helmet} from 'react-helmet';
import {useOnClickOutside} from '../hooks';
import "../scss/Menu.scss"


function Menu() {
    const [menuOpen, setMenuOpen] = useState(false)
    const navRef = useRef(null);
    const buttonRef = useRef(null);
    const wrapperRef = useRef<HTMLDivElement>(null)
    const toggleMenu = () => setMenuOpen(!menuOpen)
    useOnClickOutside(wrapperRef, () => setMenuOpen(false))

    return (
        <>
            <Helmet>
                <body className={menuOpen ? "blur" : ""}/>
            </Helmet>

            <div id="mobileMenu" ref={wrapperRef}>
                <button
                    id="menuToggle"
                    ref={buttonRef}
                    aria-label="Menu"
                    onClick={toggleMenu}
                    className={menuOpen ? "sidebarOpen" : "sidebarClosed"}
                >
                    <div className="ham-box">
                        <div className="ham-box-inner"/>
                    </div>
                </button>
                <aside className={menuOpen ? "sidebarOpen" : "sidebarClosed"} aria-hidden={!menuOpen}
                       tabIndex={menuOpen ? 1 : -1}>
                    <nav ref={navRef}>
                        {navLinks.length > 0 && (
                            <ol>
                                {
                                    navLinks.map(({url, name}, i) => (
                                        <li key={i}>
                                            <a href={url} className="borderless" onClick={() => setMenuOpen(false)}>{name}</a>
                                        </li>
                                    ))
                                }
                            </ol>
                        )}
                        <Resume/>
                    </nav>
                </aside>
            </div>
        </>
    )
}

export default Menu;