import React from "react";
import LinkedIn from "../svg/LinkedIn";
import Github from "../svg/Github";
import GitLab from "../svg/GitLab";


interface IconProperties {
    iconName: string
}

function Icon(props: IconProperties) {
    const element = () => {
        switch (props.iconName) {
            case 'LinkedIn':
                return <LinkedIn/>
            case 'GitHub' :
                return <Github/>
            case 'GitLab' :
                return <GitLab/>
            default:
                return <br/>
        }
    }
    return (
        element()
    )
}

export default Icon;