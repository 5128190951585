import React from "react";
import '../scss/Hero.scss';
import HeadShot from "./HeadShot";
import {Resume} from "./index";

function Hero() {
    return (
        <section id="hero">
            <div className="content">
                <div>
                    <h1>Hello, I am</h1>
                    <h2>Michael Lemley</h2>
                    <h3>I build Android apps and tools.</h3>
                    <p>
                        My goal is to give every person, product, and business the best possible chance for success. I
                        am a driven IT leader with over a decade of experience in web and mobile software development. I
                        leverage practices and principles rooted in servant leadership, Lean thinking, organizational
                        design, and Agile product delivery.
                    </p>
                </div>
                <div className="actionGroup">
                    <HeadShot/>
                    <div className="resume"><Resume/></div>
                </div>
            </div>
        </section>
    )
}

export default Hero;